import { template as template_e47a292c74da4857be3d97daecf6f2f4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e47a292c74da4857be3d97daecf6f2f4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
