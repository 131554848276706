import { template as template_f7f94e0f05ec443ea7a83200c7957f00 } from "@ember/template-compiler";
const WelcomeHeader = template_f7f94e0f05ec443ea7a83200c7957f00(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
