import { template as template_f32ddf02c1b444ef99298533bba97310 } from "@ember/template-compiler";
const FKLabel = template_f32ddf02c1b444ef99298533bba97310(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
