import { template as template_65f514b600f74096a4f95b0f55b0097d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_65f514b600f74096a4f95b0f55b0097d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
