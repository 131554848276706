import { template as template_fe3ed1945f084b09b3709534a33c3276 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fe3ed1945f084b09b3709534a33c3276(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
