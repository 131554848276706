import { template as template_5a9797a45a0744638670a0d6d0f8a5cb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5a9797a45a0744638670a0d6d0f8a5cb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
