import { template as template_07e2b1e807db4db5a762362964b4fa65 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_07e2b1e807db4db5a762362964b4fa65(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
